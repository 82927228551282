import { zodResolver } from "@hookform/resolvers/zod";
import { FieldPath, useForm, UseFormReturn } from "react-hook-form";
import * as z from "zod";
import { CalculatorModeSelector } from "./ModeSelector";
import CommoditySelector from "./CommoditySelector";
import {
  CalculatorModeString,
  Commodity,
  Port,
  SimpleCalculatorFormData,
  SimpleCalculatorFormSchema,
  Term,
} from "./types";
import { Button } from "@/components/ui/button";
import VoyagePortInputGroup from "./VoyagePortInputGroup";
import { CalculatorInput } from "./Inputs";
import {
  RefObject,
  use,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { FormField } from "@/components/ui/form";
import { numericOnChange } from "./helpers";
import { createPortal } from "react-dom";
import CalculationLookupSummary from "./CalculationLookupSummary";
import { useKeyboardSubmit } from "./use-keyboard-submit";
import { CommodityOption } from "@/actions/products/types";

// Note: using empty string as default since if its undefined for optional fields then
//   reset won't overwrite the user value with the default value.
const defaultValues = {
  mode: CalculatorModeString.Simple,
  commodity: "",
  quantity: "",
  tolerance: "",
  startPort: {
    port: "",
    cadence: "",
    terms: "",
    draft: "",
    portDa: "",
  },
  endPort: {
    port: "",
    cadence: "",
    terms: "",
    draft: "",
    portDa: "",
  },
};

export interface CalculatorFormProps {
  isLoading: boolean;

  commodities: CommodityOption[];
  ports: Port[];
  terms: Term[];

  onSubmit?: (data: SimpleCalculatorFormData) => void;

  urlValues?: Partial<SimpleCalculatorFormData>;

  minimized?: boolean;

  summaryNode: HTMLDivElement | null;
  mobileSummaryNode?: HTMLElement | null;

  setSortModalOpen?: (isOpen: boolean) => void;
  setFilterModalOpen?: (isOpen: boolean) => void;
}

export function isSet<T>(value: T | undefined | null | ""): value is T {
  return value !== undefined && value !== "" && value !== null;
}

export const CalculatorForm = ({
  isLoading,
  commodities,
  ports,
  terms,
  onSubmit,
  urlValues,
  minimized,
  summaryNode,
  mobileSummaryNode,

  setSortModalOpen,
  setFilterModalOpen,
}: CalculatorFormProps) => {
  const form = useForm<SimpleCalculatorFormData>({
    resolver: zodResolver(SimpleCalculatorFormSchema),
    defaultValues: {
      ...(defaultValues as any as SimpleCalculatorFormData),
      ...urlValues,

      startPort: {
        ...(defaultValues.startPort as any as SimpleCalculatorFormData["startPort"]),
        ...urlValues?.startPort,
      },
      endPort: {
        ...(defaultValues.endPort as any as SimpleCalculatorFormData["endPort"]),
        ...urlValues?.endPort,
      },
    },
  });

  useEffect(() => {
    for (const [key, value] of Object.entries(urlValues ?? {})) {
      if (isSet(value)) {
        if (typeof value === "object") {
          for (const [subKey, subValue] of Object.entries(value)) {
            form.setValue(
              `${key}.${subKey}` as FieldPath<SimpleCalculatorFormData>,
              isSet(subValue) ? subValue : ""
            );
          }
        } else {
          form.setValue(key as any, isSet(value) ? value : "");
        }
      }
    }
  }, [urlValues]);

  const formRef = useRef<HTMLFormElement>(null);

  const editingStarted = useRef(false);
  const [isEditing, setIsEditing] = useState(false);

  const startEditing = useCallback(() => {
    // Tell the form to focus on the first input when editing starts
    editingStarted.current = true;

    setIsEditing(true);
  }, [setIsEditing]);

  useLayoutEffect(() => {
    if (isEditing && editingStarted.current) {
      editingStarted.current = false;

      const firstInput = formRef.current?.querySelector?.("input, select") as
        | HTMLInputElement
        | HTMLSelectElement
        | null;
      if (firstInput) {
        firstInput?.focus?.();
      }
    }
  }, [isEditing]);

  useKeyboardSubmit(formRef, isEditing || !minimized);

  const baseMinimized = useRef(minimized);
  useEffect(() => {
    if (minimized && !baseMinimized.current) {
      setIsEditing(false);
    }

    baseMinimized.current = minimized;
  }, [minimized]);

  const quantityValRaw = form.watch("quantity");
  const calculatorMode = form.watch("mode");

  const isToleranceEnabled = isSet(quantityValRaw);
  const toleranceMemory = useRef<number | undefined>(undefined);

  const { setValue } = form;

  useEffect(() => {
    if (!isToleranceEnabled) {
      toleranceMemory.current = form.getValues("tolerance");
      setValue("tolerance", "" as any);
    } else {
      if (isSet(toleranceMemory.current)) {
        setValue("tolerance", toleranceMemory.current as any);
      }
    }
  }, [isToleranceEnabled, setValue]);

  const handleSubmit = useCallback(
    (data: SimpleCalculatorFormData) => {
      if (onSubmit) {
        onSubmit(data);
      }

      setIsEditing(false);
    },
    [onSubmit]
  );

  const stowageFactor = form.watch("stowageFactorCBFT");

  if (minimized && !isEditing) {
    if (!summaryNode) {
      return null;
    }

    return (
      <>
        {createPortal(
          <CalculationLookupSummary
            form={form}
            commodities={commodities}
            ports={ports}
            onStartEditing={() => startEditing()}
            setSortModalOpen={setSortModalOpen}
            setFilterModalOpen={setFilterModalOpen}
          />,
          summaryNode
        )}

        {mobileSummaryNode
          ? createPortal(
              <CalculationLookupSummary
                form={form}
                commodities={commodities}
                ports={ports}
                onStartEditing={() => startEditing()}
                isMobile
                setSortModalOpen={setSortModalOpen}
                setFilterModalOpen={setFilterModalOpen}
              />,
              mobileSummaryNode
            )
          : null}
      </>
    );
  }

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className="flex flex-col max-w-3xl w-[80%] max-md:w-full"
      ref={formRef}
    >
      <FormField
        control={form.control}
        name="mode"
        render={({ field, fieldState: { error } }) => (
          <CalculatorModeSelector
            calculatorMode={field.value as CalculatorModeString}
            setCalculatorMode={field.onChange}
          />
        )}
      />

      <div className="bg-frGrey-1000 md:bg-frGrey-1200 md:border-b md:border-frGrey-800 p-4 md:px-0 flex flex-col gap-2">
        <div className="gap-2 flex flex-col md:flex-row">
          <div className="md:w-1/2">
            <FormField
              control={form.control}
              name="commodity"
              render={({ field, fieldState: { error } }) => (
                <CommoditySelector
                  commodities={commodities}
                  isLoading={isLoading}
                  value={field.value ?? ""}
                  setValue={field.onChange}
                  error={error?.message}
                  stowageFactor={stowageFactor}
                  setStowageFactor={(value: number) => {
                    form.setValue("stowageFactorCBFT", value);
                  }}
                />
              )}
            />
          </div>

          <div className="flex md:flex-row gap-2 md:w-1/2">
            <FormField
              control={form.control}
              name="quantity"
              render={({ field, fieldState: { error } }) => (
                <CalculatorInput
                  id="quantity"
                  type="text"
                  label="Quantity"
                  tooltip={
                    'If "Quantity" is not specified, the freight rate is calculated based on "vessel maximum intake".'
                  }
                  placeholder="Quantity"
                  suffix="mt"
                  inputProps={{
                    ...field,
                    inputMode: "decimal",
                    value: field.value ?? "",
                  }}
                  onChange={numericOnChange(field.onChange)}
                  error={error?.message}
                  tabIndex={0}
                />
              )}
            />

            <FormField
              control={form.control}
              name="tolerance"
              render={({ field, fieldState: { error } }) => (
                <CalculatorInput
                  id="tolerance"
                  type="text"
                  label="Tolerance"
                  tooltip={
                    'In order to adjust the "Tolerance", "Quantity" must be specified.'
                  }
                  disabled={!isToleranceEnabled}
                  placeholder="Tolerance"
                  suffix="%"
                  inputProps={{
                    ...field,

                    inputMode: "decimal",
                    value: field.value ?? "",
                  }}
                  onChange={numericOnChange(field.onChange)}
                  error={error?.message}
                  tabIndex={0}
                />
              )}
            />
          </div>
        </div>

        {calculatorMode === CalculatorModeString.Advanced && (
          <>
            <div className="flex flex-col md:flex-row gap-2">
              <div className="md:w-1/2">
                <FormField
                  control={form.control}
                  name="hire"
                  render={({ field, fieldState: { error } }) => (
                    <CalculatorInput
                      type="text"
                      label="Hire"
                      placeholder="Hire"
                      suffix="$/ day"
                      inputProps={{
                        ...field,

                        inputMode: "decimal",

                        value: field.value ?? "",
                      }}
                      onChange={numericOnChange(field.onChange)}
                      error={error?.message}
                      tabIndex={0}
                    />
                  )}
                />
              </div>

              <div className="flex gap-2 md:w-1/2">
                <FormField
                  control={form.control}
                  name="sfoPrice"
                  render={({ field, fieldState: { error } }) => (
                    <CalculatorInput
                      type="text"
                      id="sfoPrice"
                      label="Bunker (VLSFO)"
                      placeholder="Price"
                      suffix="$/ mt"
                      inputProps={{
                        ...field,

                        inputMode: "decimal",

                        value: field.value ?? "",
                      }}
                      onChange={numericOnChange(field.onChange)}
                      error={error?.message}
                      tabIndex={0}
                    />
                  )}
                />

                <FormField
                  control={form.control}
                  name="mgoPrice"
                  render={({ field, fieldState: { error } }) => (
                    <CalculatorInput
                      type="text"
                      id="mgoPrice"
                      label="Bunker (LSMGO)"
                      placeholder="Price"
                      suffix="$/ mt"
                      inputProps={{
                        ...field,

                        inputMode: "decimal",

                        value: field.value ?? "",
                      }}
                      onChange={numericOnChange(field.onChange)}
                      error={error?.message}
                      tabIndex={0}
                    />
                  )}
                />
              </div>
            </div>

            <div className="mt-2">
              <p className="text-sm text-center font-light text-frGrey-500 md:text-left">
                If optional values are not specified, the best data or
                estimation is used.
              </p>
            </div>
          </>
        )}
      </div>

      <VoyagePortInputGroup
        multiple={false}
        mode={calculatorMode}
        terms={terms}
        ports={ports}
        isLoading={isLoading}
        form={form}
        tabIndex={0}
      />

      <div className="flex gap-4 px-4 py-6 bg-frGrey-1200 md:px-0">
        <Button
          type="reset"
          variant="darkOutline"
          size="xs"
          className="w-1/2 rounded-full border-primary-200"
          onClick={() => {
            form.reset({
              ...(defaultValues as any as SimpleCalculatorFormData),
              mode: form.getValues("mode"),
            });
          }}
          tabIndex={0}
        >
          Reset
        </Button>
        <Button
          variant="darkFilled"
          size="xs"
          className="w-1/2 rounded-full"
          type="submit"
          disabled={isLoading}
          tabIndex={0}
        >
          Calculate
        </Button>
      </div>
    </form>
  );
};
